import React, { useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { Row, Form, FormGroup, Input, Label } from "reactstrap";
import {connect} from 'react-redux';

function ScreenConnexion(props) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  var submitSignIn = async function () {
    
    var rawResponse = await fetch("https://backend-cabinetdeneuro.vercel.app/users/sign-in", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `userName=${login}&password=${password}`,
    });
    var response = await rawResponse.json();
    console.log(response);
    if (response.userToken != null) {
      props.addToken(response.userToken);
      history.push("/BackOffice");
    }
  };

  return (
    <Row className="structure">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "10%", marginBottom: "5%" }}> Connexion</h1>
        <Form>
          <FormGroup floating>
            <Input
              id="Identifiant"
              placeholder="Identifiant"
              type="text"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <Label for="name">Identifiant</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="password"
              placeholder="Mot de passe"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Label for="lastName">Mot de passe</Label>
          </FormGroup>
        </Form>
        <button
          className="button"
          onClick={() => {
            submitSignIn();
          }}
        >
          Envoyer
        </button>
      </div>
    </Row>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    addToken: function (token) {
      dispatch({ type: "addToken", token: token });
    },
  };
}

 export default connect(null, mapDispatchToProps)(ScreenConnexion);
