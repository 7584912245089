import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Form, FormGroup, Input, Label } from "reactstrap";

export default function ScreenPrendreRDV() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [displayError, setDisplayError] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState("");

  var sendMail = async function () {
    var rawResponse = await fetch("https://backend-cabinetdeneuro.vercel.app/send-mail", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `name=${name}&lastName=${lastName}&phone=${phone}&email=${email}&message=${message}`,
    });
    var response = await rawResponse.json();
    console.log(response);
    setName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setMessage("");
    setConfirm(true);
  };
  useEffect(() => {
    if (error != "") {
      setErrorAlert(<div className="alertError">{error}</div>);
    }
  }, [error]);

  useEffect(() => {
    if (confirm == true) {
      setConfirmAlert(<div className="alertSuccess">Message envoyé</div>);
    }
  }, [confirm]);

  return (
    <Row className="structure">
        <h3 style={{ marginTop: "-2%", marginBottom:"2%" }}>
          Vous pouvez me contacter par téléphone (laissez un message sur le
          répondeur) ou par le biais de ce formulaire (précisez bien votre
          numéro de téléphone portable svp) :{" "}
        </h3>
        <div className="paragraphe" style={{padding:"20px"}}>
          <Row>
            <Col>
              <Form>
                <FormGroup floating>
                  <Input
                    id="name"
                    placeholder="Prénom"
                    type="text"
                    style={{ height: "6vh" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Label for="name">Prénom</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="lastName"
                    placeholder="Nom"
                    type="text"
                    style={{ height: "6vh" }}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <Label for="lastName">Nom</Label>
                </FormGroup>
              </Form>
            </Col>
            <Col>
              <Form>
                <FormGroup floating>
                  <Input
                    id="email"
                    placeholder="Email"
                    type="email"
                    style={{ height: "6vh" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Label for="email">Email</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="phone"
                    placeholder="Numéro de téléphone"
                    type="number"
                    style={{ height: "6vh" }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <Label for="phone">Téléphone</Label>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: "5%" }}>
            <Form>
              <FormGroup floating>
                <Input
                  id="message"
                  placeholder="Message"
                  style={{ height: "20vh" }}s
                  type="textarea"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Label for="message" style={{ fontSize: "80%" }}>
                  Message
                </Label>
              </FormGroup>
            </Form>
          </Row>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {displayError ? errorAlert : null}
            {confirm ? confirmAlert : null}
            <button
              className="button"
              onClick={() => {
                if (name == "") {
                  setError("Vous n'avez pas renseigné votre prénom");
                } else if (lastName == "") {
                  setError("Vous n'avez pas renseigné votre nom");
                } else if (email == "") {
                  setError("Vous n'avez pas renseigné votre adresse mail");
                } else if (!email.includes("@") || !email.includes(".")) {
                  setError("Adresse mail invalide");
                } else if (phone == "") {
                  setError(
                    "Vous n'avez pas renseigné votre numéro de téléphone"
                  );
                } else if (message == "") {
                  setError("Vous n'avez pas renseigné de message");
                } else if (
                  phone.length != 10 ||
                  phone.charAt(0) != 0 ||
                  !(phone.charAt(1) == 6 || phone.charAt(1) == 7)
                ) {
                  setError("Numéro de téléphone invalide");
                } else {
                  setError("");
                  setDisplayError(false);
                  sendMail();
                }
              }}
            >
              Envoyer
            </button>
          </div>
        </div>
    </Row>
  );
}
