import React, { useEffect, useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row } from "reactstrap";

export default function ScreenPaymentSuccess() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setmail] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setID] = useState("");

  useEffect(() => {
    setName(localStorage.getItem("name"));
    setLastName(localStorage.getItem("lastName"));
    setPhone(localStorage.getItem("phone"));
    setmail(localStorage.getItem("mail"));
    setID(localStorage.getItem("seanceID"));
  }, []);

  useEffect(() => {
    reserveAppointments(id);
  }, [id]);

  var reserveAppointments = async (id) => {
    if (localStorage.getItem("confirmationSent") != 1) {
      var rawResponse = await fetch(
        `https://backend-cabinetdeneuro.vercel.app/appointment/addAttendee/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: `firstname=${name}&lastname=${lastName}&mail=${mail}&phone=${phone}`,
        }
      );
      var response = await rawResponse.json();
      console.log(response);
      var date = new Date(response.date).toLocaleDateString("fr");
      var time = new Date(response.date).toLocaleTimeString("fr");

      await fetch(
        `https://backend-cabinetdeneuro.vercel.app/send-mail-confirmation`,
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: `date=${date}&time=${time}&mail=${mail}`,
        }
      );
    }
    localStorage.setItem("confirmationSent", 1);
  };

  return (
    <Row className="structure">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: "20px", marginTop: "10%" }}>
          {" "}
          Paiement réussi, vous allez reçevoir un mail de confirmation.
        </p>
      </div>
    </Row>
  );
}
