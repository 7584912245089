import React, { useState, useEffect } from "react";
import { Row, Modal, Col } from "reactstrap";

const DisplayAppointments = () => {
  const [visible, setVisible] = useState(false);
  const [seances, setSeances] = useState([]);

  var getAppointments = async () => {
    var rawResult = await fetch(
      "https://backend-cabinetdeneuro.vercel.app/appointment/getAppointments"
    );
    var result = await rawResult.json();
    setSeances(result.appointments);
  };

  var deleteAppointment = async (id) => {
    await fetch(
      `https://backend-cabinetdeneuro.vercel.app/appointment/deleteAppointment/${id}`,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: ``,
      }
    );
  };

  useEffect(() => {
    getAppointments();
  }, []);

  return (
    <Row>
      {seances.map((seance, i) => (
        <Col sm="4" md="3" key={i}>
          <div
            onClick={() => setVisible(!visible)}
            className="cards"
            style={{
              border:
                seance.nbrPlaces != 0 ? "#9b9b9b solid 2px" : "red solid 2px",
            }}
          >
            <h1>{new Date(seance.date).toLocaleDateString("fr")}</h1>
            <h1>{new Date(seance.date).toLocaleTimeString("fr")}</h1>
            <h2>{seance.price}€</h2>
            <h3>Groupe {seance.gender}</h3>
            <h3 style={{ color: seance.nbrPlaces != 0 ? "#315499" : "red" }}>
              Places : {seance.nbrPlaces}
            </h3>
          </div>
          <Modal
            centered
            toggle={() => setVisible(!visible)}
            isOpen={visible}
            size="xl"
            onCancel={() => setVisible(!visible)}
          >
            <table style={{ margin: "5%" }}>
              <thead>
                <tr style={{ color: "#315499" }}>
                  <td>Prénom</td>
                  <td>Nom</td>
                  <td>Téléphone</td>
                  <td>Email</td>
                </tr>
              </thead>
              <tbody>
                {seance.attendees.map((attendee, i) => (
                  <tr>
                    <td>{attendee.firstname}</td>
                    <td>{attendee.lastname}</td>
                    <td>{attendee.phone}</td>
                    <td>{attendee.mail}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{display:"flex", justifyContent:"center", marginBottom:"5%"}}>
              <button
                className="button"
                style={{ width: "50%" }}
                onClick={() => {deleteAppointment(seance._id)}}
              >
                Supprimer cette séance
              </button>
            </div>
          </Modal>
        </Col>
      ))}
    </Row>
  );
};

export default DisplayAppointments;
