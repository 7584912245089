import React from "react";
import "../App.css";
import { Row } from "reactstrap";
import FormAddAppointment from "../components/FormAddAppointment";
import DisplayAppointments from "../components/DisplayAppointments";

import { connect } from "react-redux";
function ScreenBackOffice(props) {
  if (props.token == "") {
    return <p style={{display:"flex", justifyContent:"center", marginTop:"20%"}}> Veuillez vous connecter pour accéder à cette page</p>;
  } else {
    return (
      <Row className="structure">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ marginBottom: "5%", marginTop: "10%" }}>
            {" "}
            Ajouter une session de groupe
          </h1>
          <FormAddAppointment />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ marginBottom: "5%", marginTop: "10%" }}>
            {" "}
            Séances prévues :
          </h1>
        </div>
        <DisplayAppointments />
      </Row>
    );
  }
}
function mapStateToProps(state) {
  return { token: state.token };
}
export default connect(mapStateToProps, null)(ScreenBackOffice);
