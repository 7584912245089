import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

export default function ScreenPrendreRDVGroupe() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setmail] = useState("");
  const [phone, setPhone] = useState("");
  const [visible, setVisible] = useState(false);
  const [seances, setSeances] = useState([]);
  const [error, setError] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [displayError, setDisplayError] = useState(true);
  const [displayPayment, setDisplayPayment] = useState(true);

  var getAppointments = async () => {
    var rawResult = await fetch(
      "https://backend-cabinetdeneuro.vercel.app/appointment/getAppointments"
    );
    var result = await rawResult.json();
    setSeances(result.appointments);
  };

  useEffect(() => {
    getAppointments();
  }, []);

  useEffect(() => {
    if (error != "") {
      setErrorAlert(<div className="alertError">{error}</div>);
    }
  }, [error]);

  useEffect(() => {
    localStorage.setItem("name", name);
  }, [name]);
  useEffect(() => {
    localStorage.setItem("lastName", lastName);
  }, [lastName]);
  useEffect(() => {
    // storing input name
    localStorage.setItem("mail", mail);
  }, [mail]);
  useEffect(() => {
    // storing input name
    localStorage.setItem("phone", phone);
  }, [phone]);

  return (
    <Row className="structure">
      <h1>Séances disponibles :</h1>
      <div className="paragraphe">
        <Row>
          {seances.map((seance, i) => (
            <Col xs="6" lg="3" key={i}>
              <div
                className="cards"
                style={{
                  border:
                    seance.nbrPlaces != 0
                      ? "#9b9b9b solid 2px"
                      : "red solid 2px",
                }}
                onClick={() => {
                  if (seance.nbrPlaces != 0) setVisible(!visible);
                }}
              >
                <h1>{new Date(seance.date).toLocaleDateString("fr")}</h1>
                <h1>{new Date(seance.date).toLocaleTimeString("fr")}</h1>
                <h2>{seance.price}€</h2>
                {seance.nbrPlaces != 0 ? (
                  <div>
                    <p style={{ color: "grey", fontSize: "12px" }}>
                      Séance reservée aux {seance.gender}
                    </p>
                  </div>
                ) : (
                  <p style={{ color: "red", fontSize: "18px" }}>COMPLET</p>
                )}
              </div>
              <Modal
                centered
                toggle={() => setVisible(!visible)}
                isOpen={visible}
                size="xl"
                onCancel={() => setVisible(!visible)}
              >
                <ModalHeader toggle={() => setVisible(!visible)}>
                  <h3>Réservation de séance Bas les masques</h3>
                </ModalHeader>

                <p style={{ marginTop: "3%", marginLeft: "10%" }}>
                  Entrez vos coordonnées pour vous inscrire à la séance du{" "}
                  {new Date(seance.date).toLocaleDateString("fr")} à{" "}
                  {new Date(seance.date).toLocaleTimeString("fr")}
                </p>
                <Row style={{ padding: "10px", margin: "5%" }}>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Form>
                        <FormGroup floating>
                          <Input
                            id="name"
                            placeholder="Prénom"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <Label for="name">Prénom</Label>
                        </FormGroup>
                        <FormGroup floating>
                          <Input
                            id="lastName"
                            placeholder="Nom"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <Label for="lastName">Nom</Label>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Form>
                        <FormGroup floating>
                          <Input
                            id="mail"
                            placeholder="Email"
                            type="mail"
                            value={mail}
                            onChange={(e) => setmail(e.target.value)}
                          />
                          <Label for="mail">Email</Label>
                        </FormGroup>
                        <FormGroup floating>
                          <Input
                            id="phone"
                            placeholder="Numéro de téléphone"
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <Label for="phone">Téléphone</Label>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "-2%",
                    marginBottom: "10%",
                  }}
                >
                  {displayError ? errorAlert : null}

                  {displayPayment ? (
                    <button
                      className="button"
                      style={{ width: "30%" }}
                      onClick={() => {
                        setDisplayError(true);
                        if (name == "") {
                          setError("Vous n'avez pas renseigné votre prénom");
                        } else if (lastName == "") {
                          setError("Vous n'avez pas renseigné votre nom");
                        } else if (mail == "") {
                          setError("Vous n'avez pas renseigné votre mail");
                        } else if (!mail.includes("@") || !mail.includes(".")) {
                          setError("Adresse mail invalide");
                        } else if (phone == "") {
                          setError(
                            "Vous n'avez pas renseigné votre numéro de téléphone"
                          );
                        } else if (
                          phone.length != 10 ||
                          phone.charAt(0) != 0 ||
                          !(phone.charAt(1) == 6 || phone.charAt(1) == 7)
                        ) {
                          setError("Numéro de téléphone invalide");
                        } else {
                          setError("");
                          setDisplayError(false);
                          setDisplayPayment(false);
                          localStorage.setItem("seanceID", seance._id);
                        }
                      }}
                    >
                      Valider
                    </button>
                  ) : (
                    <form
                      action="http://backend-cabinetdeneuro.vercel.app/create-checkout-session"
                      method="POST"
                    >
                      <button
                        className="button"
                        style={{
                          width: "18vw",
                          backgroundColor: "green",
                          color: "white",
                        }}
                        type="submit"
                      >
                        Passer au paiement
                      </button>
                    </form>
                  )}
                </div>
              </Modal>
            </Col>
          ))}
        </Row>
      </div>
    </Row>
  );
}
