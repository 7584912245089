import React from "react";

const header = () => {
    return (
    <div className="contact">
    <img src="../icon.png" className="icone" alt="Logo"></img>
    <div  style={{minWidth:"35%"}}>
      <h4 style={{marginBottom:"2px"}}>Cabinet de Neuropsychologie</h4>
      <h5>4 bis rue Fabre d'Eglantine <br/>
      75012 - Paris <br/>
      Tel : 01.45.84.28.13 <br/>
      Mail : golouboff@cabinetdeneuropsychologie.fr </h5>
    </div>
    <div  style={{minWidth:"35%",marginLeft:"calc(35px + 0.05vw) "}}>
      <h4 style={{marginBottom:"2px"}}>Nathalie GOLOUBOFF</h4>
      <h5>Neuropsychologue<br/>
      Docteur en Psychologie Cognitive<br/>
      Diplômée de l’Université Paris V<br/>
      N° SIRET : 50857827500036</h5>
    </div>
  </div>
    );
  };
  
  export default header;
  